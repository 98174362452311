


















import { Component, Vue } from "vue-property-decorator";
@Component({})
export default class VNotFound404 extends Vue {}
